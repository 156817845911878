<template>
  <v-app>
    <AppHeader />
    <div id='main' class='mt-5 text-center font-weight-bold'>
      <p>現在ホームページのメンテナンス中です。</p>
      <p>今しばらくお待ちください。</p>
    </div>
    <AppFooter />
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  display: flex;
  flex-direction: column;
}

#main-container {
  max-width: 768px;
}

#main {
  flex-grow: 1;
}
</style>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

export default {
  components: { AppHeader, AppFooter },
}
</script>
